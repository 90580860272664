import { MonoTypeOperatorFunction, Observable, OperatorFunction, pipe } from 'rxjs';
import { map, switchMap, mergeMap, tap, take } from 'rxjs/operators';
import { EntityStore } from '@datorama/akita';

export function log<T>(): MonoTypeOperatorFunction<T> {
  return pipe(tap((v) => console.log(`log:`, v)));
}

export function lon<T>(name: string): MonoTypeOperatorFunction<T> {
  return pipe(tap((v) => console.log(`logWithName(${name}):`, v)));
}

export interface PageInfo {
  endCursor: string | undefined;
  hasNextPage: boolean | undefined;
  hasPreviousPage: boolean | undefined;
  startCursor: string | undefined;
}

export interface GraphileResult {
  pageInfo: PageInfo;
  totalCount: number;
  nodes: any[];
}

export function updatePageInfo<T>(akitaEntityStore: EntityStore): OperatorFunction<GraphileResult, any[]> {
  return (source$) =>
    source$.pipe(
      map((graphileResponse) => {


        if (graphileResponse?.nodes?.length > 20) {
          console.warn('Oy! look like you query too much', graphileResponse);
        }
        if (graphileResponse) {
          akitaEntityStore.update({ pageInfo: graphileResponse.pageInfo, totalCount: graphileResponse.totalCount });
        } else {
          console.log('warning graphileResponse is missing', graphileResponse);
        }
        return graphileResponse?.nodes;
      })
    );
}

export function pageInfoToAkita<T>(akitaEntityStore: EntityStore, fn: any, action: 'set' | 'add' = 'set'): OperatorFunction<T, any[]> {
  return (source$) =>
    source$.pipe(
      // tap(() => akitaEntityStore.setLoading(true)),
      mergeMap(fn),
      updatePageInfo(akitaEntityStore),
      // tap((books) => console.log('books', books)),
      tap((books) => akitaEntityStore[action](books)),
      take(1)
      // tap(() => akitaEntityStore.setLoading(false))
    );
}
